import React from 'react'
import Typist from 'react-typist'
import '../../node_modules/react-typist/dist/Typist.css'

const Greeter = () => {
  return (
    <div className="segment" id="greeter">
      <div className="greeterText">
        <Typist.Delay ms={300}/>
          <span>Hi, my name is Alex. To learn more about me you can visit the <a href="#about">summary section</a>, the <a href="#about2">skills section</a>, and <a href="#about3">check me out elsewhere</a>.</span>
      </div>
    </div>
  )
}

export default Greeter
